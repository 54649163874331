// вроде как можно удалить

import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import * as Tooltip from "@radix-ui/react-tooltip"
import * as HoverCard from "@radix-ui/react-hover-card"

import MenuIcon from "../icons/MenuIcon"
import useAuthStore from "../Auth/AuthStore"
import UserLoader from "../User/UserLoader"
import MotherTongueChecker from "../User/MotherTongueChecker"
import LangChecker from "../User/LangChecker"
import MotherTongueDialog from "./../Lang/MotherTongueDialog"
import { useTranslation } from "react-i18next"

import LangSelector from "./../Lang/LangSelector"
import { config } from "../api"
import { Settings, DoorClosed, DoorOpen, MessageCircleMore } from "lucide-react"

function Header() {
    const logout = useAuthStore((state) => state.logout)
    const { t } = useTranslation("header")

    const logoutHandler = () => {
        logout()
        window?.$sleek?.resetUser()
        navigate("/")
    }

    return (
        <div className="w-20 h-full border-r flex flex-col justify-between items-center bg-neutral border-secondary-200">
            {/* нужен что то типо систем менеджера который будет отслеживать такие штуки как показывать пап или нет */}
            <UserLoader />
            <MotherTongueChecker />
            <LangChecker />

            <Link
                to="/"
                className="text-primary font-semibold mt-6 mb-2 font-lora"
            >
                Gloisia
            </Link>

            <LangSelector />
            <div className="flex flex-col border-b border-secondary-200 w-full" />

            <div className="flex-grow flex flex-col items-center space-y-8 mt-6">
                <MyTooltip text={t("header:menuCards")} sideOffset={4}>
                    <Link
                        className="flex group"
                        to="/cards"
                        activeClassName="active"
                        partiallyActive={true}
                    >
                        <MenuIcon
                            cards
                            className="w-8 h-8  text-secondary-300 hover:text-primary transition-all duration-150 group-[.active]:text-primary"
                        />
                    </Link>
                </MyTooltip>

                <MyTooltip text={t("header:menuRules")} sideOffset={4}>
                    {config.isDev ? (
                        <Link className="flex group" to="/tmp">
                            <MenuIcon
                                rules
                                className="w-7 h-7 text-secondary-300 hover:text-primary transition-all duration-150 group-[.active]:text-primary"
                            />
                        </Link>
                    ) : (
                        <InfoTooltip
                            title={"Rules"}
                            description={
                                "A system of rules and exercises that tests user knowledge and generates exercises based on identified gaps, using the spaced repetition method."
                            }
                        >
                            <MenuIcon
                                rules
                                className="w-7 h-7 text-secondary-300 hover:text-primary group-[.active]:text-primary transition-all duration-150"
                            />
                        </InfoTooltip>
                    )}
                </MyTooltip>

                <MyTooltip text={t("header:menuDiary")} sideOffset={4}>
                    <Link
                        to="/diary"
                        activeClassName="active"
                        partiallyActive={true}
                        className="flex group"
                    >
                        <MenuIcon
                            stories
                            className="w-8 h-8 text-secondary-300 hover:text-primary transition-all duration-150 rotate-90 group-[.active]:text-primary"
                        />
                    </Link>
                </MyTooltip>

                <MyTooltip text={t("header:menuStories")} sideOffset={4}>
                    <InfoTooltip
                        title={"Stories"}
                        description={
                            "A collection of texts for learning, designed for various proficiency levels. Each text is accompanied by an audio version and may be aimed at exploring a specific language aspect or everyday situation."
                        }
                    >
                        <MenuIcon
                            translator
                            className="w-7 h-7 text-secondary-300 hover:text-primary group-[.active]:text-primary transition-all duration-150 rotate-90 cursor-pointer"
                        />
                    </InfoTooltip>
                </MyTooltip>
            </div>

            <div className="flex flex-col items-center mb-8 space-y-6">
                <MyTooltip text={t("header:menuFeedback")} sideOffset={4}>
                    <FeeedbackButton />
                </MyTooltip>

                <MyTooltip text={t("header:menuSettings")} sideOffset={4}>
                    <EditButton />
                </MyTooltip>

                <MyTooltip text={t("header:menuLogOut")} sideOffset={0}>
                    <div onClick={logoutHandler} className="relative group">
                        <DoorClosed
                            size={24}
                            className="text-secondary-300 group-hover:opacity-0 transition-all duration-300"
                        />
                        <DoorOpen
                            size={24}
                            className="text-primary absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-300"
                        />
                    </div>
                </MyTooltip>
            </div>
        </div>
    )
}

function FeeedbackButton() {
    const feedbackHandler = () => {
        window.$sleek?.open()
    }

    return (
        <MessageCircleMore
            onClick={feedbackHandler}
            size={24}
            className="text-secondary-300 hover:text-primary transition-colors duration-300"
        />
    )
}

function EditButton() {
    const [isMotherTongueOpen, setIsMotherTongueOpen] = useState(false)

    const changeLangHandler = () => {
        setIsMotherTongueOpen(true)
    }

    return (
        <>
            <MotherTongueDialog
                isOpen={isMotherTongueOpen}
                onClose={() => setIsMotherTongueOpen(false)}
            />
            <Settings
                onClick={changeLangHandler}
                size={24}
                className="text-secondary-300 hover:text-primary transition-colors duration-300"
            />
        </>
    )
}

const MyTooltip = ({ text, children, sideOffset = 0 }) => {
    return (
        <Tooltip.Provider delayDuration={0} disableHoverableContent={true}>
            <Tooltip.Root>
                <Tooltip.Trigger>{children}</Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        side="bottom"
                        className="bg-gray-800 text-white text-xs rounded px-2 py-0.5 text-center w-min min-w-16"
                        sideOffset={sideOffset}
                    >
                        {text}
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    )
}

const InfoTooltip = ({ title, description, children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [onHover, setOnHover] = useState(false)

    const onOpenChangeHandler = (isOpen) => {
        if (!onHover) {
            setIsOpen(false)
        }
    }

    return (
        <HoverCard.Root open={isOpen} onOpenChange={onOpenChangeHandler}>
            <HoverCard.Trigger
                onClick={() => setIsOpen(!isOpen)}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
            >
                {children}
            </HoverCard.Trigger>
            <HoverCard.Portal>
                <HoverCard.Content
                    sideOffset={12}
                    side="right"
                    className="p-4 bg-white shadow-2xl rounded-lg w-80 border-t border-r border-gray-100 border-1"
                >
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                            <div className="font-bold">
                                <span>{title}</span>{" "}
                                <span className="text-amber-600">
                                    (To be added soon)
                                </span>
                            </div>
                            <div className="text-sm">{description}</div>
                        </div>
                    </div>
                    <HoverCard.Arrow className="fill-current text-white" />
                </HoverCard.Content>
            </HoverCard.Portal>
        </HoverCard.Root>
    )
}

export default Header

export const enHeaderResources = {
    header: {
        menuCards: "Cards",
        menuRules: "Rules",
        menuDiary: "Diary",
        menuStories: "Stories",
        menuFeedback: "Feedback",
        menuSettings: "Settings",
        menuLogOut: "Log Out",
    },
}

export const ruHeaderResources = {
    header: {
        menuCards: "Карточки",
        menuRules: "Правила",
        menuDiary: "Дневник",
        menuStories: "Истории",
        menuFeedback: "Обратная связь",
        menuSettings: "Настройки",
        menuLogOut: "Выход",
    },
}
